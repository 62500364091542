import React from 'react';
import { useDispatch } from 'react-redux';
import { ModalActions } from '@rally/renew-active/store';
import { UHCLink } from '@rally/rewards-view/color';

const sharedSubtitle = 'Enter your ZIP Code to find a list of fitness locations near you.';
const sharedTitle = 'Find a Fitness Location';

const SharedSubtitleUpdate: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <div>
      Some Medicare Advantage plans and all Group Medicare and Medicare Supplement plans with Renew Active provide
      access to additional Premium Locations,{' '}
      <a
        href="#"
        style={{ color: UHCLink, fontWeight: 'bold', textDecoration: 'underline' }}
        onClick={(evt) => {
          evt.preventDefault();
          dispatch(ModalActions.renewActiveMemberModal(true));
        }}
      >
        sign in to your member site
      </a>{' '}
      to view your plan's fitness network.
    </div>
  );
};

export const gymSearchHeaderText = {
  subtitle: sharedSubtitle,
  subtitleUpdate: <SharedSubtitleUpdate />,
  title: `${sharedTitle} Near You`,
};

export const mobileGymSearchHeaderText = {
  subtitle: sharedSubtitle,
  subtitleUpdate: <SharedSubtitleUpdate />,
  title: sharedTitle,
};
